import React, { useEffect, useState } from "react";
import Apply from "./sections/apply";
import { useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import "../../../assets/activation-payment/activationPayment.css";
import Accordion from "react-bootstrap/Accordion";
import Declined2 from "../global-elements/declined2";
import DeclinedTemporal from "../global-elements/declinedTemporal";
import Payment from "./sections/payment";
import Pendfee from './sections/pendfee';
import Enjoy from "./sections/enjoy";
import { useHistory } from "react-router-dom";



function Layout() {
  const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
  const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
  const currentRoute = useLocation().pathname;
  const [activeKey, setActiveKey] = useState("0");
  const resultStep = useStoreState(state => state.displayResult);
  const history = useHistory();

  const ROUTE_TO_OPENED_TAB = {
    "": "1",
    "pendinfo": "2",
    "decision": "2",
    "pendfee": "2",
    "payment": "3",
    "enjoy": "4",
  }

  useEffect(() => {
    document.querySelector(
      "title"
    ).textContent = `${brandSettings.ProductName} - Mastercard`;

    // Apply styles based on the product color
    const customAccordionHeaderElements = document.querySelectorAll(
      ".accordion-item button"
    );
    customAccordionHeaderElements.forEach((element) => {
      element.style.background = brandSettings.BannerBgColor;
    });
    setActiveKey(ROUTE_TO_OPENED_TAB[currentRoute.split('/').pop()] || "1");
  }, [brandSettings.BannerBgColor, brandSettings.ProductName, currentRoute]);

  useEffect(() => {
    if (!resultStep && window.location.pathname !== MainPath) {
      history.push(MainPath);
    }
  });

  return (
    <>
      <div className="container mt-5 mb-5">
        <Accordion
          activeKey={activeKey}
          className="custom-accordion"
          onSelect={() => {}}
          flush
        >
          <Accordion.Item eventKey="1">
            <Accordion.Header style={{ opacity: activeKey !== "1" ? 0.5 : 1 }}>
              {" "}
              <span className="step-number">1</span> Apply
            </Accordion.Header>
            <Accordion.Body>
              <Apply />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header style={{ opacity: activeKey !== "2" ? 0.5 : 1 }}>
              <span className="step-number">2</span> Get a response in just a few seconds
            </Accordion.Header>
            <Accordion.Body>
              {currentRoute === (MainPath + "pendinfo") && <DeclinedTemporal />}
              {currentRoute === (MainPath + "decision") && <Declined2 />}
              {currentRoute === (MainPath + "pendfee") && <Pendfee />}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header style={{ opacity: activeKey !== "3" ? 0.5 : 1 }}>
              <span className="step-number">3</span> 
              Pay my processing fee
            </Accordion.Header>
            <Accordion.Body>
              {currentRoute === (MainPath + "payment") && <Payment />}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header style={{ opacity: activeKey !== "4" ? 0.5 : 1 }}>
              <span className="step-number">4</span>
              Enjoy!
            </Accordion.Header>
            <Accordion.Body>
              {currentRoute === (MainPath + "enjoy") && <Enjoy />}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}

export default Layout;
