import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import Wildcard from "../../../../services/wildcard";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ValidatorService from "../../../../services/validator";
import TC from "../../add-ons/TC";
import Esign from "../../add-ons/esign";
import marketing from "../../../../services/marketing";
import "../../../../assets/terms/dm-terms.css";
import Acknowledgements from "../../add-ons/Acknowledgements";
import CreditProtection from "../../add-ons/CreditProtection";
import { useEffect } from "react";
import ErrorModal from "../../../body/forms/components/ErrorModal";
import InvalidStateModal from "../../global-elements/invalidStateModal";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PixelsService from '../../../../services/pixels';
import ConfirmSmsConsent from "../../global-elements/confirmsSmsConsent";
import SubmitApplicationService from "../../../../services/submitApplication";
import AddCardholder from "../../global-elements/addCardholder";
import Econsent from '../../global-elements/eConsent';

function Terms() {
  const Setting = useStoreState((state) => state.siteSettingsModel);
  const setGrandBanckDeclined = useStoreActions((actions) => actions.siteSettingsModel.setGrandBanckDeclined);
  const info = useStoreState((state) => state.applicantModel.applicant);
  const setInfo = useStoreActions((actions) => actions.applicantModel.setApplicant);
  const text = useStoreState((state) => state.dmText.terms);
  const setApplicationResult = useStoreActions((actions) => actions.applicationResultModel.setApplicationResult);
  const pendingInfo =  ["FROZEN", "FROZENPREQUAL", "PENDFEE", "PENDFUNDING", "PENDINFO", "PENDINTERNAL"];
  const resultStep = useStoreActions((actions) => actions.setDisplayResult);
  const history = useHistory();
  const wildcard = new Wildcard();
  const validatorService = new ValidatorService();
  const methods = useForm({ mode: "onSubmit" });
  const [isLoading, setLoading] = useState(false);
  const utms = new marketing();
  const [errorShow, setErrorShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showInvalidStateModal, setShowInvalidStateModal] = useState(false);
  const closeError = () => setErrorShow(false);
  const [showAdditionalCardHolderForm, setShowAdditionalCardHolderForm] =useState(false);
  const termsPixels = useStoreState(state => state.termsPixels);
  const setTermsPixel = useStoreActions(actions => actions.setTermsPixel);
  const getRoutingSettings = useStoreActions(state => state.routingSettingsModel.getRoutingSettings);
  const PixelService = new PixelsService();
  const submitApplicationService = SubmitApplicationService();
  const setDeclineOffers = useStoreActions(actions => actions.siteSettingsModel.setDeclineOffers);

  useEffect(() => {
    if (!termsPixels) {
      PixelService.pageView("/Terms").event("google","CS","Terms");
      
      setTermsPixel(true)
    }
    getRoutingSettings({ Url: Setting.domain, Route: window.location.pathname});
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    //unregister controls to avoid validations
    
    if (showAdditionalCardHolderForm===false) {
        methods.unregister(["additionalCardholderFirstName", "additionalCardholderMI", "additionalCardholderLastName", "hasAdditionalCardholder"])
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [showAdditionalCardHolderForm]);



  const submit = async (data) => {
    if (showAdditionalCardHolderForm===false) {
      delete data["additionalCardholderFirstName"]
      delete data["additionalCardholderLastName"]
      delete data["additionalCardholderMI"]
    }
    setLoading(true);
    if (!validatorService.preValidations(data, methods, info)) {
        await submitApplication();
    }
    setLoading(false);
  };

  async function submitApplication() {
   
    let payload = await submitApplicationService.submitApplication(info, true);
    let response = payload.Payload || false;
    if (response) {
      
      setApplicationResult(response);
      if (response.IsGrandbank === true) {
        setGrandBanckDeclined(true);
      }

      if (response.result != null && payload.Success === true) {

        utms.updateSessionReport("appResult",response.ResultWebPageHash.toUpperCase());
        utms.updateSessionReport("applicantId", response.ref_number);
        window.cl = response.credit_limit ?? 0;
        setInfo({ index: "step", value: 3 });
        response.ResultWebPageHash = response.result.toLowerCase() === "sys_error" ? "sys_error" : response.ResultWebPageHash;
        response.decline_message = response.decline_message === "" ? "We are unable to verify your identity." : response.decline_message;
        
        resultStep(true);
        //Decline Offers for BMG box
        setDeclineOffers(response.DeclineOffers || null);

        window.scrollTo(0, 0);
        if (response.result.toUpperCase() === "APPROVED") {
          history.push("/approved" + window.location.search);
          return;
        }
        if (pendingInfo.includes(response.result.toUpperCase())) {
          history.push("/pendinfo" + window.location.search);
          return;
        }
      }
      history.push("/decision" + window.location.search);
      return;
    } else {
      setErrorMessage("An unknown error has occured. Please try again.");
      setErrorShow(true);
      setLoading(false);
    }
  }

  return (
    <>
 
      <div className="row" id="header-text-container" style={{ backgroundColor: Setting.brandSettings.Primary }}>
        <div className="col-12 text-center">
          <h1 id="offer-header-text" className="mt-2 mb-2">
            {wildcard.proccess(text.block1.title)}
          </h1>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <ErrorModal size="sm" show={errorShow} message={errorMessage} closeError={closeError} />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submit)} >
            <div>
              <div 
                className="add-authorized-user-text"
                onClick={() =>
                  setShowAdditionalCardHolderForm(!showAdditionalCardHolderForm)
                }
              >
                <span>
                  <FontAwesomeIcon icon={showAdditionalCardHolderForm ? faMinus : faPlus} />
                </span>
                Add Authorized User (Optional)
              </div>

              {/* Authorized User Form */}
              {showAdditionalCardHolderForm && (
                <AddCardholder></AddCardholder>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">E-Sign Disclosure</h1>
              </div>
              <Esign />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Pricing and Terms</h1>
              </div>
              <div className="col-12 ">
                <div className="terms-wrapper">
                  <TC data={{ productCode: info.ProductCode }} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Credit Protection</h1>
              </div>
              <CreditProtection />
            </div>
            <div className="row">
              <div className="col-12">
                <h1 className="terms-header-title">Acknowledgements</h1>
              </div>
              <Econsent />
              <Acknowledgements data={{ productCode: info.ProductCode }} />
            </div>
            {Setting.smsConcentModuleCS === true && (
                <div className="row mt-4 pr-0 pl-3">
                    <div className="col-12">
                        <ConfirmSmsConsent></ConfirmSmsConsent>
                    </div>
                </div>
            )}
            <div className="row mt-3">
              <div className="text-center mx-auto">
                <button
                  onClick={(e) => {utms.trackEvent(30, "CS", "", Setting.productSettings.ProductId, "Flow");PixelService.event("google", "CS", "Accept Offer");}}
                  disabled={isLoading}
                  id="dm-submit-app"
                  style={{
                    backgroundColor: Setting.mainSettings.Flow === 'CS' ? Setting.brandSettings.Primary : Setting.brandSettings.Secondary
                  }}
                  className="btn text-small mt-3 rounded-pill p-3"
                  type="submit"
                >
                  {isLoading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>

      <InvalidStateModal
        showInvalidStateModal={showInvalidStateModal}
        setShowInvalidStateModal={setShowInvalidStateModal}
      />
    </>
  );
}

export default Terms;
