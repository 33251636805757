import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Landing from "./sections/landing";
import Approved from "../global-elements/approved2";
import Terms from "./sections/terms";
import Declined from "../global-elements/declined";
import { useStoreState } from "easy-peasy";
import Error404 from "../../systempages/error404/Error404";
import DeclinedTemporal from "../global-elements/declinedTemporal";
import GrandBankPage from "../global-elements/grandbank/grandbankPage";

function Layout() {
 
  const brandSettings = useStoreState((state) => state.siteSettingsModel.brandSettings);
  const termsStep = useStoreState(state => state.displayPrequalTerms);
  const resultStep = useStoreState(state => state.displayResult);

  useEffect(() => {
    document.querySelector("title").textContent = `${brandSettings.ProductName} - Mastercard`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      {/* Landing page*/}
      <Route path="/" component={() => <Landing />} exact />
      {/* Terms page */}
      <Route path="/terms" component={() => (termsStep) ? <Terms /> : <Redirect to={"/"+window.location.search} />} exact />
      {/* ResultPages page */}
      <Route path="/approved" component={() => (termsStep) ? <Approved /> : <Redirect to={"/"+window.location.search} />} exact />
      <Route path="/decision" component={() => (termsStep) ? <Declined /> : <Redirect to={"/"+window.location.search} />} exact />
      <Route path="/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/"+window.location.search} />} exact/>
      <Route path="/pendinfo" component={() => (resultStep) ? <DeclinedTemporal /> : <Redirect to={"/"+window.location.search} />} exact />
      <Route  component={() => <Error404 />}  />
    </Switch>
  );
}

export default Layout;
