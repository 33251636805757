import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useForm } from "react-hook-form";
import AxiosWrapper from "../../../../services/axios";
import { useHistory } from "react-router-dom";
import ErrorModal from '../../../body/forms/components/ErrorModal';
import { Link } from "react-router-dom";
import AdobeScript from '../../../../services/adobeScript';

function CheckingAccount(props) {
    const setting = useStoreState(state => state.siteSettingsModel);
    const { handleSubmit, register, watch, formState: { errors } } = useForm({ mode: "onSubmit" });
    const applicant = useStoreState((state) => state.applicantModel.applicant);
    const applicationResult = useStoreState((state) => state.applicationResultModel.applicationResult);
    const axios = new AxiosWrapper();
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
    const closeError = () => setErrorShow(false);
    const adobe = new AdobeScript();
    const isAuthorized = watch("authorize");

    let [info, setInfo] = useState({
        ApplicantId: applicationResult.ref_number,
        AccountTypeId: null,
        PaymentTypeId: 1,
        PaymentTypeMethod: "ACH",
        Amount: applicationResult.security_deposit,
        CardNumber: null,
        CVV: null,
        BillingZipCode: "",
        ExpirationDateMonth: null,
        ExpirationDateYear: null,
        RoutingNumber: "",
        AccountNumber: "",
        UserId: "/make-payment",
        ApplicationReference: setting.productSettings.ApplicationSourceReference
    });

    const Submit = async (data) => {
        setLoading(true);
        sessionStorage.setItem("Payment", 1);
        const response = await axios.post("/make-payment", { ...info, AccountTypeId: data.accountType === 'checking' ? 1 : 2 }, true);
        adobe.event('bankAccount_submit')
        if (!response || typeof response.Success === undefined || response.Success === false) {
            setLoading(false);
            setErrorShow(true);
            adobe.event('errorPayment');
            setErrorMessage(response.Error);
            return;
        }
        setLoading(false);
        window.scrollTo(0, 0);
        history.push(MainPath + "enjoy");
        return;
    };

    return (
        <div className='col-12 p-0'>
            <ErrorModal size='sm' show={errorShow} message={errorMessage} closeError={closeError} />
            <div className='card gbCard noRadiusTop' >
                <div className='card-body no-bottom-margin  row' style={{ "marginBottom": "0 !important" }}>
                    <div className='col-12'>
                        <form onSubmit={handleSubmit(Submit)} className='w-100'>
                            <div className='row mb-0'>
                                <div className='col-12 text-left'>
                                    <p className='p-0 payment-description'>
                                        Making your payment with a checking or savings account is a good method but it will delay the opening of your account while we wait for your funds to clear. The hold period for checking or savings account payments is 10 days. If you want to have the fastest access to your new account, consider making a card payment by clicking <Link to={MainPath + "payment"} >here</Link>.
                                    </p>
                                </div>
                            </div>
                            <div className="row card-box mb-3" style={{ width: "95%", marginLeft: "inherit" }}>
                                <div className="result-message">
                                    <div ><strong>Approved Applicant:</strong> {applicant.FirstName || "Test"} {applicant.LastName || "Test"}</div>
                                    <div ><strong>Reference Number:</strong> {applicationResult.ref_number || "16212152"}</div>
                                    {/* this needs to change when we include processinf fee in the backend response */}
                                    <div ><strong>Processing Fee:</strong> ${applicationResult.security_deposit}</div>
                                </div>
                            </div>
                            <div className='row mb-0'>
                                <div className='col-12'>
                                    <h3 style={{ fontWeight: "normal" }}>How will you be paying today?</h3>
                                </div>
                            </div>
                            <div className="row mt-0">
                                <div className="col-12">
                                    <div>Type of Account</div>
                                </div>
                            </div>
                            <div className="row my-3 d-flex align-items-center">
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                    <div className="radioGroup">
                                        <div className="radio-btn d-flex align-items-center mr-3">
                                            <input type="radio" className="mr-0" value={"checking"} {...register("accountType", { required: true })} />
                                            <div className="font-weight-bold">Checking Account</div>
                                        </div>
                                        <div className="radio-btn d-flex align-items-center">
                                            <input type="radio" className="mr-0" value={"saving"} {...register("accountType", { required: true })} />
                                            <div className="font-weight-bold">Savings Account</div>
                                        </div>
                                    </div>
                                    {errors.accountType ? errors.accountType && <span className='error-message'> Please select a payment method.</span> : ''}
                                </div>
                                {errors.hasCheckingAccount && errors.hasCheckingAccount.message.map((message) => (
                                    <div className="error-message" key={"accountType-" + message}> This field is missing. Please select an option. </div>
                                ))}
                                <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                    <small className='font-italic'>Paying with checking or savings account will take an additional 10 days for the funds to clear</small>
                                </div>
                            </div>
                            <div className='row mb-0 mt-3'>
                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                    <div className='row mb-0'>
                                        <div className='col-12 '>
                                            <h3>Bank Name</h3>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 '>
                                            <div className="input-container">
                                                <input maxLength={60} type="text" className="form-control" placeholder="Bank Name" {...register("bankName", { required: true })} />
                                            </div>
                                            {errors.bankName ? errors.bankName && <span className='error-message'> This field is missing or invalid. Please fill it correctly.</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                    <div className='row mb-0'>
                                        <div className='col-12 debitInfo'>
                                            <h3>Bank Routing Number</h3>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12 '>
                                            <div className="input-container">
                                                <input
                                                    onInput={(e) => {
                                                        const inputValue = e.target.value.replace(/\D/g, '');
                                                        e.target.value = inputValue;
                                                        setInfo({ ...info, RoutingNumber: inputValue })
                                                    }}
                                                    maxLength={9}
                                                    type="text" className="form-control" placeholder="Bank Routing Number" {...register("RoutingNumber", { required: true })}
                                                />
                                            </div>
                                            {errors.RoutingNumber ? errors.RoutingNumber && <span className='error-message'> This field is missing or invalid. Please fill it correctly.</span> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                    <div className='row mb-0'>
                                        <div className='col-12 debitInfo'>
                                            <h3>Bank Account Number</h3>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="input-container">
                                                <input
                                                    onInput={(e) => {
                                                        const inputValue = e.target.value.replace(/\D/g, '');
                                                        e.target.value = inputValue;
                                                        setInfo({ ...info, AccountNumber: inputValue })
                                                    }}
                                                    maxLength={17}
                                                    type="text" className="form-control" placeholder="Bank Account Number" {...register("AccountNumber", { required: true })}
                                                />
                                            </div>
                                            {errors.AccountNumber ? errors.AccountNumber && <span className='error-message'> This field is missing or invalid. Please fill it correctly.</span> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-0 mt-3'>
                                <div className='col-md-7 d-none d-md-block'></div>
                                <div className='col-md-5 ccol-xs-12'>
                                    <img
                                        src="/account-routing-number.png"
                                        alt="account and routing number"
                                        className='mt-1'
                                        style={{ width: "40%" }}
                                    />
                                </div>
                            </div>
                            <div className='row mb-0'>
                                <div className='col-12'>
                                    <div className="form-check check-fields">
                                        <div className="input-container">
                                            <input className="form-check-input" type="checkbox" value="" id="authorize" {...register("authorize", { required: true })} />
                                            <label className="form-check-label pt-0" htmlFor="authorize">
                                                By checking this box, I sign and agree to authorize {setting.brandSettings.BankName} to initiate this credit, debit or prepaid transaction from the account listed above in the amount specified.
                                            </label>
                                        </div>
                                        {errors.authorize ? errors.authorize && <span className='error-message'> You must acknowledge before continuing. </span> : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-0 mt-1 justify-content-center">
                                <div className="col-12 d-flex">
                                    {errors.request
                                        ? errors.request && (
                                            <span
                                                className="error-message mt-2 mb-3"
                                                style={{ display: "block" }}
                                            >
                                                An error has occurred. And your payment was declined
                                                please check your card information and try agaian.
                                            </span>
                                        )
                                        : ""}
                                    <button
                                        variant="primary"
                                        className="pay-processing-button"
                                        type="submit"
                                        disabled={isLoading || !isAuthorized}
                                        style={{ backgroundColor: setting.brandSettings.BannerBgColor, border: "1px solid transparent", opacity: (isLoading || !isAuthorized) ? 0.5 : 1, marginTop: "1.5em" }}
                                    >
                                        {isLoading ? 'Submitting...' : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CheckingAccount;