import React, { useEffect, useState } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import AxiosWrapper from "../../../../services/axios";
import SubmitApplicationService from "../../../../services/submitApplication";
import AudioEyeSupport from "../../../../services/audioEyeSupport";

function MfaEmail() {

    const Setting = useStoreState((state) => state.siteSettingsModel);
    const SubmitApp = SubmitApplicationService()
    const setApplicationResult = useStoreActions(actions => actions.applicationResultModel.setApplicationResult);
    const ada = new AudioEyeSupport();
    const info = useStoreState(state => state.applicantModel.applicant);
    const ApplicantResult = useStoreState(state => state.applicationResultModel.applicationResult);
    const mfaemail = useStoreState((state) => state.mfaemail);
    const setMfaEmail = useStoreActions(actions => actions.setMfaEmail);
    const history = useHistory();
    const [code, setCode] = useState('')
    const [showExpired, setShowExpired] = useState(false)
    const [errorText, setErrortext] = useState(false)
    const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
    const displayResult = useStoreState((state) => state.displayResult);
    const client = new AxiosWrapper()
    const [loading, setLoading] = useState(false)
    const sessionTimeoutId = useStoreState(state => state.sessionTimeoutId);
    // eslint-disable-next-line no-unused-vars
    const [timerId, setTimerId] = useState(null);
    const [showResend, setShowResend] = useState(false);
    const fields = Array(6).fill("")
  


    useEffect(() => {
        if(mfaemail === true && displayResult === false){
            sendSmsCode('Email')
            handleInputBlock('block2');
            Object.defineProperty(window, 'bridge', {
                set: (value) => {
                    setCode(value);
                },
                configurable: true,
            });
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mfaemail])

    function handleInputBlock(blockId) {
        let inputs = document.querySelectorAll(`#${blockId} .mfaInputs`);
    
        inputs.forEach((input, index, array) => {

            if (index === 0) {
                input.addEventListener('paste', (e) => {
                    // Get pasted data
                    const pasteData = (e.clipboardData || window.clipboardData).getData('text');
    
                    // Validate if it's 6 digits
                    if (/^\d{6}$/.test(pasteData)) {
                        // Split the string and assign each digit to the inputs
                        const digits = pasteData.split('');
                        digits.forEach((digit, i) => {
                            array[i].value = digit;

                        });
                        window.bridge = pasteData
                        array[5].focus(); // Set focus to the last input after pasting
                    }
    
                    e.preventDefault(); // Prevent the default paste behavior
                });
            }
        });
    
        // Initially set focus to the first empty input
        const firstEmptyIndex = [...inputs].findIndex(input => input.value === '');
        if (firstEmptyIndex !== -1) {
            inputs[firstEmptyIndex].focus();
        }
    }



    function getEmailMasked(){

        return info.Email.slice(0, 2) + '***@' +  (info.Email.split('@')[1] || '');
    }


    async function sendSmsCode(type){
    
        let result = await client.post('/send-Mfa', {contactIdentifier : info.Email, contactType : type, ApplicantId : ApplicantResult.ref_number, WebUrl : Setting.domain}, true)
        if(result && result.Success === true){
            sessionStorage.setItem('sessionToken', result.Payload)
            return
        }
        setErrortext('An Error has occurred. Please try again later.')
    }

    function reSendSms(){
        sendSmsCode('Email')
        setShowExpired(false)
    }

    function stopTimer(){
        if (timerId) {
          clearInterval(timerId); // Clear the interval
          setTimerId(null); // Reset the timer ID
        }
    }

    async function Submit(){
        setLoading(true)
        let result = await client.post('/verify-Mfa', {verifyCode : code, ApplicantId : ApplicantResult.ref_number, WebUrl : Setting.domain}, true);
    
        if(result && result.Success === true){
            sessionStorage.setItem('sessionToken', result.Payload)
            stopTimer();
            await SubmitApplication()
            return
        }
        if(result && result.Success === false){
            if(result.StatusCode === 401){
                if(result.Error === 'Expired'){
                    
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                    setErrortext('The verification code you entered is invalid, or your code has expired.')
                    setCode('')
                    document.querySelectorAll(`#block2 .mfaInputs`)[0].focus()
                    setShowExpired(true)
                  
                    return
                }
                if(result.Error === 'NoMatch'){
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                    setErrortext('The verification code you entered is invalid, or your code has expired.')
                    setCode('')
                    document.querySelectorAll(`#block2 .mfaInputs`)[0].focus()
                    return
                }
                if(result.Error === 'TooManyAttempts'){
                    setLoading(false)
                    sessionStorage.setItem('sessionToken', result.Payload)
                   
                    setApplicationResult({result : 'MFATOOMANYATTEMPTS'});
                    setdisplayResult(true)
                    window.scrollTo(0, 0);
                    ada.sendDYEvent({name : 'code_failure'});
                    history.push(`/decision`+window.location.search) 
                   
                    return
                }
               
            }
        }
        if(result === false){
            SubmitApplication()
            return
        }
        setLoading(false)
        setErrortext('An Error has occurred. Please try again later.')
    }

    async function SubmitApplication(){
        
        try{
           
            ada.sendDYEvent({name : 'code_success'});
            let result = await SubmitApp.submitApplicationOffer(info);
            setApplicationResult(result);
            window.scrollTo(0, 0);
            if(result){
                clearInterval(sessionTimeoutId)
                setMfaEmail(false)
                setdisplayResult(true)
                if(result.decision === "APPROVED"){
               
                setApplicationResult({ result: "APPROVED" });
                history.push(`/approved`+window.location.search);
                return true;
                }
                history.push(`/decision`+window.location.search);
                return true;
            }
            setErrortext('An Error has occurred. Please try again later.')
            return false;
        }catch(Error){
            console.log(Error);
            setErrortext('An Error has occurred. Please try again later.')
        }
     
}

function setFocusOnBackward(index){
    let inputs = document.querySelectorAll(`#block2 .mfaInputs`);
    let flag = index -1 < 0 ? 0 : index
    flag = flag > 5 ? 5 : flag
    inputs[flag].focus();
}

    return (
        <>
        <Modal show={mfaemail} backdrop="static" id="mfa-modal" onHide={() => true} centered >
            <Modal.Header>
            <Modal.Title className="justify-content-center align-items-center MfaTitle " >We've {showResend ? 're-sent' : 'sent'} your verification code.</Modal.Title>
            </Modal.Header>
            <Modal.Body className="font18">
                <div className="row">
                    <div className="col-12">
                        <p className="mb-1">A 6-digit verification code was {showResend ? 're-sent' : 'sent'} to <strong>{ getEmailMasked() }</strong> </p>
                        <p>Please allow up to 1 minute to receive it.</p>
                        <p className="mb-0"><strong>Enter verification code</strong></p>
                    </div>
                    <div className="col-12">
                        {
                            (errorText !== false) && (
                                <p style={{fontSize : '1.2em'}} className="mb-0 mt-3 text-center error-message"><strong>{ errorText }</strong></p>
                            )
                        } 
                    </div>
                    <div className="col-12">
                        <div className="input-container-modal" id="block2">
                            {   
                                fields.map((value, index) => (
                                    <input value={code[index] || ''}  maxLength={1}
                                    disabled={loading}
                                    onKeyDown={(e) => {
                                       
                                        if(e.nativeEvent.code === "Backspace" || e.nativeEvent.keyCode === 8){
                                            let temp = code.slice(0, -1)
                                            
                                            setCode(code.slice(0, -1))
                                            setFocusOnBackward(temp.length)
                                        }
                                    }}
                                    onInput={(e) => { 
                                        let value = (typeof e.nativeEvent.data === "undefined" || e.nativeEvent.data === null) ? e.target.value : e.nativeEvent.data;
                                        if (/[0-9]/.test(value)) {
                                            if(/^[0-9]+$/.test(value) || value === ""){
                                                if(code.length < 6){
                                                    setCode(code + value)
                                                    let temp = code + value;
                                                    setFocusOnBackward(temp.length)
                                                }
                                            }
                                        } 
                                        if(e.nativeEvent.inputType === "deleteContentBackward"){
                                            let temp = code.slice(0, -1)
                                            
                                            setCode(code.slice(0, -1))
                                            setFocusOnBackward(temp.length)
                                        }
                                    }} type="text" class=" mfaInputs"   />
                                ))
                            }
                        </div>
                   </div>
                   
                    
                </div>
                <div className="row mt-2 MfaSmallerText">
                    <div className="col-12 text-center">
                        <p className="mb-1">By selecting Submit, I authorize a one-time<br />email message to verify my device.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <button onClick={() => {Submit(); setShowExpired(false)}} disabled={code.length < 6 || loading} style={{marginTop : 'none !important'}} className={((code.length < 6 ) ? 'mfaDisabled' : '') + " btn text-small mt-3 MfaEmailButton mfabutton mt-0 btn-info"}> 
                         {!loading ? 'Submit' : 'Submitting...'} 
                        </button>
                    </div>
                </div>
                <div className="row mt-2 mb-1 MfaSmallerText">
                    <div className="col-12 text-center">
                        {
                            <>
                            { showExpired === true && (
                                <p className="mb-1"><span className="fakeLink" onClick={() => {reSendSms(); setShowResend(true)}}>Resend code</span></p>
                            )}       
                            
                            </>
                        }
                        
                        
                    </div>
                </div>
                <div className="row MfaSmallerText mt-2">
                    <div className="col-12 text-center">
                        <p>Standard message & data rates may apply</p>
                    </div>
                </div>
            </Modal.Body>
          
        </Modal>
        </>
    );
}

export default MfaEmail;
