import React, { useEffect } from "react";
import {Redirect, Route, Switch } from "react-router-dom";
import Landing from "./sections/landing";
import Approved from "../global-elements/approved2";
import Declined from "../global-elements/declined";
import { useStoreState, useStoreActions } from "easy-peasy";
import DeclinedTemporal from "../global-elements/declinedTemporal";
import Error404 from "../../systempages/error404/Error404";
import GrandBankPage from "../global-elements/grandbank/grandbankPage";

function Layout() {

  const resultStep        = useStoreState(state => state.displayResult);
  const MainPath          = useStoreState(state => state.siteSettingsModel.MainPath);
  const PathBasedSite          = useStoreState(state => state.siteSettingsModel.PathBasedSite);
  const setting           = useStoreState(state => state.siteSettingsModel);
  const setProductPath    = useStoreActions(actions => actions.siteSettingsModel.setMainPath);

    useEffect(() => {
        document.querySelector("title").textContent = `${setting.brandSettings.ProductName || ""} - Mastercard`;
        if(MainPath === "/" && PathBasedSite === true){
            setProductPath((window.location.pathname.split("/")[1] === '') ? "/" : "/" + window.location.pathname.split('/')[1] + "/") 
            console.log(window.location.pathname.split('/')[1] + "/")
 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  useEffect(() => {
    document.querySelector("title").textContent = `${setting.brandSettings.ProductName} - Mastercard`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {setting.mainSettings.Flow === "CFC" && PathBasedSite === false && (
        <Switch>
          {/* Landing page No form */}
          <Route path="/" component={() => <Landing />} exact />
          {/* Landing page No form */}
          <Route path="/approved" component={() => (resultStep) ?  <Approved /> : <Redirect to={"/"+window.location.search} />} exact />
          {/* Terms page No form */}
          <Route path="/decision" component={() => (resultStep) ? <Declined /> : <Redirect to={"/"+window.location.search} />} exact/>
          <Route path="/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/"+window.location.search} />} exact/>
          <Route path="/pendinfo" component={() => (resultStep) ? <DeclinedTemporal /> : <Redirect to={"/"+window.location.search} />} exact />
          <Route  component={() => <Error404 />}  />
        </Switch>
      )}

      {setting.mainSettings.Flow === "CFC" && PathBasedSite === true && (
        <Switch>
          <Route path="/" component={() => (MainPath !== "/") ? <Redirect to={"/" + MainPath + window.location.search} /> : <Error404 />} exact />
            <Route path="/:MainPath" component={() => (MainPath !== "/" && "/" + window.location.pathname.split('/')[1] + "/" === MainPath) ? <Landing /> : <Error404 />} exact />
            <Route path="/:MainPath/approved" component={() => (resultStep) ? <Approved /> : <Redirect to={"/" + window.location.pathname.split('/')[1] + window.location.search} />} exact />
            {/* Terms page No form */}
            <Route path="/:MainPath/decision" component={() => (resultStep) ? <Declined /> : <Redirect to={"/" + window.location.pathname.split('/')[1] + window.location.search} />} exact/>
            <Route path="/:MainPath/grandbank" component={() => (resultStep) ? <GrandBankPage /> : <Redirect to={"/" + window.location.pathname.split('/')[1] + window.location.search} />} exact/>
            <Route path="/:MainPath/pendinfo" component={() => (resultStep) ? <DeclinedTemporal /> : <Redirect to={"/" + window.location.pathname.split('/')[1] + window.location.search} />} exact />
            <Route  component={() => <Error404 />}  />
        </Switch>
      )}
    </>
    
  );
}

export default Layout;
