import React from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function InvalidStateModal() {
  const MainPath = useStoreState((state) => state.siteSettingsModel.MainPath);
  const info = useStoreState(state => state.siteSettingsModel.brandSettings);
  const text = useStoreState((state) => state.prequalText.step2);
  const invalidState = useStoreState((state) => state.invalidState);
  const setInvalidState = useStoreActions((actions) => actions.setInvalidState);
  const setdisplayResult = useStoreActions((actions) => actions.setDisplayResult);
  const history = useHistory();



  return (
    <>
      <Modal show={invalidState} backdrop="static" id="invalid-state-modal" onHide={() => setInvalidState(false)} centered >
        <Modal.Header>
          <Modal.Title className="justify-content-center align-items-center invalidState" style={{fontSize : '1.6em'}}>Thank you for your interest in {info.ProductName.toUpperCase() === 'FIT' ? `${info.ProductName.toUpperCase()}` : `${info.ProductName}`} Mastercard!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{text.invalidStateModal.invalidStateMessage}</Modal.Body>
        <Modal.Footer className="">
            <div className="col-12 text-center">
            <Button variant="secondary" onClick={() => { setdisplayResult(true);window.scrollTo(0, 0);setInvalidState(false); ;history.push(MainPath+"decision" + window.location.search)}} >
             Close
           </Button>
            </div>
         
          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InvalidStateModal;
